import React, { Component } from 'react'
import firebase from './Firebase'
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import QRCode from 'qrcode.react'
// import {
//     BrowserRouter as Router,
//     Route,
//     Link
//   } from 'react-router-dom';
import domtoimage from 'dom-to-image';

import cookie from 'react-cookies'



let db = firebase.firestore()

const qrOptions = {
    size: 200,
    fgColor: '#000000',
    bgColor: '#ffffff',
    level: 'H',
    renderAs: 'canvas',
}


export default class Dashboard extends Component {

    constructor(props) {
        super(props)
        this.onAddRow = this.onAddRow.bind(this)
        this.createCustomModalFooter = this.createCustomModalFooter.bind(this)
        this.handleModalClose = this.handleModalClose.bind(this)
        this.handleSave = this.handleSave.bind(this)
        this.insertEmployee = this.insertEmployee.bind(this)
        this.importExcel = this.importExcel.bind(this)
        this.downloadQR = this.downloadQR.bind(this)
        this.companyDetails = this.companyDetails.bind(this)
        this.mainDashboard = this.mainDashboard.bind(this)
        this.employeeDetails = this.employeeDetails.bind(this)
        this.foodTransaction = this.foodTransaction.bind(this)

        this.state = {
            dummy: '',
            qrcodeValue: '',
            loginUser: undefined,
            empList: [
                {
                    'emp_id': 'XXXX',
                    'emp_name': 'XXXXXXXX',
                    'position': 'XXXX',
                    'pd': 'XXXX',
                    'emp_mobile': 'XXXX'

                }
            ],
            totalEmployees: 0,
            activeEmployees: 0,
            InActiveEmployees: 0

        }
    }

    createCustomModalFooter = () => {

    }

    handleModalClose = () => {

    }


    handleSave = () => {

    }




    componentDidMount() {


        const login = cookie.load('login', { path: '/' });



        if (login === undefined) {
            this.props.history.push('/');
            return;
        }
        this.setState({ loginUser: JSON.parse(login) }, () => {


            this.getDataEmployeesData()



        })






    }

    getDataEmployeesData = () => {



        db.collection("employees").doc(this.state.loginUser.name).collection('data')
            .get()
            .then((querySnapshot) => {
                let data = querySnapshot.docs.map(function (documentSnapshot) {
                    return documentSnapshot.data();
                });
                this.setState({ empList: data, totalEmployees: data.length, activeEmployees: data.length, InActiveEmployees: 0 })
            })
            .catch((error) => {
                console.log("Error getting documents: ", error);
            });


    }


    importExcel = () => {

        this.props.history.push('/importExcel');


    }

    downloadQR = () => {
        const canvas = document.getElementById("1234567");

        domtoimage.toJpeg(canvas, { quality: 0.95, bgcolor: 'white' })
            .then((dataUrl) => {
                var link = document.createElement('a');
                link.download = this.state.qrcodeValue + ".jpg";
                link.href = dataUrl;
                link.click();
                this.setState({ qrcodeValue: '' })
            });


    }

    foodTransactionMonthly = () => {
        this.props.history.push('/foodTransactionMonthly')

    }

    onAddRow = (row) => {
        if (row.position !== "" && row.emp_id !== "" && row.emp_mobile !== "" &&
            row.emp_name !== "") {
            this.insertEmployee(row)
        } else {
            alert('Please Fill All The Fields')
        }

    }

    insertEmployee = (row) => {
        this.setState({ qrcodeValue: row.emp_id }, () => {
            db.collection("employees").doc(this.state.loginUser.name).collection('data').add({
                'emp_id': this.state.loginUser.companyId + "_" + row.emp_id.toUpperCase(),
                'emp_name': row.emp_name.toUpperCase(),
                'company': this.state.loginUser.name.toUpperCase(),
                'position': row.position.toUpperCase(),
                'emp_mobile': row.emp_mobile,
                'is_active': true,
                'download': 'Download',
                'subcidyValue': row.subcidyValue !== '' ? row.subcidyValue : '0'
            })
                .then((docRef) => {

                    this.state.empList.push({
                        'emp_id': this.state.loginUser.companyId + "_" + row.emp_id.toUpperCase(),
                        'emp_name': row.emp_name.toUpperCase(),
                        'company': this.state.loginUser.name.toUpperCase(),
                        'position': row.position.toUpperCase(),
                        'emp_mobile': row.emp_mobile,
                        'is_active': true,
                        'download': 'Download',
                        'subcidyValue': row.subcidyValue !== '' ? row.subcidyValue : '0'

                    })
                    this.setState({ qrcodeValue: '' })
                })
                .catch((error) => {
                    alert('error')
                    console.error("Error adding document: ", error);
                });
        })

    }

    onRowClick = (row, columnIndex, rowIndex) => {
        if (row === undefined || row.emp_id === undefined || row.emp_id === '') { return; }

        if (columnIndex === 5) {
            this.setState({
                qrcodeValue: row.emp_id
            }, () => {
                this.downloadQR(row.emp_id)

            })
        } else {

            this.props.history.push({
                pathname: '/foodTransaction',
                data: row.emp_id
            })

        }


    }

    mainDashboard = () => {
        this.props.history.push('/mainDashboard');
    }

    employeeDetails = () => {
        this.props.history.push('/employeeDetails');
    }

    companyDetails = () => {
        this.props.history.push('/companyDetails');
    }

    foodTransaction = () => {
        this.props.history.push('/foodTransaction')
    }

    logout = () => {
        cookie.remove('login', { path: '/' })

        this.props.history.push('/')
    }




    render() {



        const options = {
            onAddRow: (row) => this.onAddRow(row),
            onRowClick: (row, columnIndex, rowIndex) => this.onRowClick(row, columnIndex, rowIndex),
            paginationSize: 50,
            paginationShowsTotal: true,
            searchDelayTime: 500,
            sizePerPage: 50,
        }


        return (

            <div id="wrapper">


                <ul class="navbar-nav bg-gradient-primary sidebar sidebar-dark accordion" id="accordionSidebar">

                    <a class="sidebar-brand d-flex align-items-center justify-content-center" href="index.html">
                        <div class="sidebar-brand-icon rotate-n-15">
                            <i class="fas fa-laugh-wink"></i>
                        </div>
                        <div class="sidebar-brand-text mx-3">{this.state.loginUser !== undefined ? this.state.loginUser.name : ''} Admin </div>
                    </a>

                    <hr class="sidebar-divider my-0"></hr>

                    <li class="nav-item active">
                        <a class="nav-link" onClick={this.mainDashboard}>
                            <i class="fas fa-fw fa-tachometer-alt"></i>
                            <span>Dashboard</span></a>
                    </li>

                    <hr class="sidebar-divider" />

                    <div class="sidebar-heading">
                        Interface
</div>

                    <li class="nav-item active">
                        <a class="nav-link" onClick={this.companyDetails}>
                            <i class="fas fa-fw fa-chart-area"></i>
                            <span>Companys Details</span></a>
                    </li>

                    <li class="nav-item active">
                        <a class="nav-link" onClick={this.employeeDetails}>
                            <i class="fas fa-fw fa-table"></i>
                            <span>Employee Details</span></a>
                    </li>

                    <li class="nav-item active">
                        <a class="nav-link" onClick={this.foodTransaction}>
                            <i class="fas fa-utensils"></i>
                            <span>Food Transaction</span></a>
                    </li>

                    <li class="nav-item active">
                        <a class="nav-link" onClick={this.foodTransactionMonthly}>
                            <i class="fas fa-fw fa-table"></i>
                            <span>Food Transaction Monthly</span></a>
                    </li>

                    <li class="nav-item active">
                        <a class="nav-link" onClick={this.logout}>
                            <i class="fas fa-sign-out-alt"></i>
                            <span>logout</span></a>
                    </li>

                    <hr class="sidebar-divider d-none d-md-block"></hr>

                    <div class="text-center d-none d-md-inline">
                        <button ref={a => this.button = a} class="rounded-circle border-0" id="sidebarToggle"></button>
                    </div>


                </ul>

                <div id="content-wrapper" class="d-flex flex-column">

                    <div id="content">


                        <div class="row" style={{ marginTop: 20, marginLeft: 20 }}>

                            <div class="col-xl-3 col-md-6 mb-4">
                                <div class="card border-left-primary shadow h-100 py-2">
                                    <div class="card-body">
                                        <div class="row no-gutters align-items-center">
                                            <div class="col mr-2">
                                                <div class="text-xs font-weight-bold text-primary text-uppercase mb-1">Total Employees</div>
                                                <div class="h5 mb-0 font-weight-bold text-gray-800">{this.state.totalEmployees}</div>
                                            </div>
                                            <div class="col-auto">
                                                <i class="fas fa-calendar fa-2x text-gray-300"></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div class="col-xl-3 col-md-6 mb-4">
                                <div class="card border-left-success shadow h-100 py-2">
                                    <div class="card-body">
                                        <div class="row no-gutters align-items-center">
                                            <div class="col mr-2">
                                                <div class="text-xs font-weight-bold text-success text-uppercase mb-1">Active Employeees</div>
                                                <div class="h5 mb-0 font-weight-bold text-gray-800">{this.state.activeEmployees}</div>
                                            </div>
                                            <div class="col-auto">
                                                <i class="fas fa-dollar-sign fa-2x text-gray-300"></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-xl-3 col-md-6 mb-4">
                                <div class="card border-left-info shadow h-100 py-2">
                                    <div class="card-body">
                                        <div class="row no-gutters align-items-center">
                                            <div class="col mr-2">
                                                <div class="text-xs font-weight-bold text-info text-uppercase mb-1">InActive Employeees</div>
                                                <div class="row no-gutters align-items-center">
                                                    <div class="col-auto">
                                                        <div class="h5 mb-0 mr-3 font-weight-bold text-gray-800">{this.state.InActiveEmployees}</div>
                                                    </div>

                                                </div>
                                            </div>
                                            <div class="col-auto">
                                                <i class="fas fa-clipboard-list fa-2x text-gray-300"></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>






                        </div>




                        <div class="container-fluid">
                            <div class="card shadow mb-4">
                                <div class="card-header py-3">
                                    <h6 class="m-0 font-weight-bold text-primary float-left">Employee Details</h6>
                                    <div onClick={this.importExcel} class="d-none d-sm-inline-block btn btn-sm btn-primary shadow-sm float-right"><i class="fas fa-plus fa-sm text-white-50"></i> import from excel</div>
                                </div>
                                <div class="card-body">
                                    <div class="table-responsive">

                                        <BootstrapTable data={this.state.empList} options={options} exportCSV={true} insertRow={true} search={true} striped={true} bordered={true} hover={true} pagination={true}>
                                            <TableHeaderColumn width={'15%'} dataField="emp_id" isKey={true}  >Employee ID</TableHeaderColumn>
                                            <TableHeaderColumn width={'15%'} dataField="emp_name" >Name</TableHeaderColumn>
                                            <TableHeaderColumn width={'15%'} dataField="position" >Position</TableHeaderColumn>
                                            {/* <TableHeaderColumn width={'15%'} dataField="pd"   editable={{ type: 'select', options: { values: pd } }}>Payment Details</TableHeaderColumn> */}
                                            <TableHeaderColumn width={'15%'} dataField="subcidyValue"    >Subcidy Value</TableHeaderColumn>
                                            <TableHeaderColumn width={'15%'} dataField="emp_mobile" >Mobile Number</TableHeaderColumn>
                                            <TableHeaderColumn width={'15%'} dataField="download" editable={false} export={false} >Download QR</TableHeaderColumn>

                                        </BootstrapTable>



                                    </div>
                                </div>
                            </div>

                        </div>


                    </div>

                    {this.state.qrcodeValue !== '' && <div style={{ backgroundColor: 'white' }} className="container">
                        <div style={{ width: 200, height: 330 , paddingTop : 20}} id={'1234567'}>
                            <QRCode
                                value={this.state.qrcodeValue}
                                size={qrOptions.size}
                                fgColor={qrOptions.fgColor}
                                bgColor={qrOptions.bgColor}
                                level={qrOptions.level}
                                renderAs={qrOptions.renderAs}
                                includeMargin={true}
                            >
                            </QRCode>

                            <h4 style={{ justifyItems: 'center', alignContent: 'center', alignItems: 'center', color: 'black', textAlign: 'center', backgroundColor: 'white', width: 200 }}> {this.state.qrcodeValue}</h4>

                        </div>
                    </div>}


                </div>

            </div>
        )
    }

}