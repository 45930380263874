import React, { Component } from 'react'
import firebase from './Firebase'
import cookie from 'react-cookies'



let db = firebase.firestore()

export default class Login extends Component {

  constructor(props) {
    super(props)
    this.email = this.email.bind(this)
    this.password = this.password.bind(this)
    this.submit = this.submit.bind(this)
    this.state = {
      email: '',
      password: ''
    }

    const login = cookie.load('login', { path: '/' });
    if (login !== undefined) {
      this.props.history.push('/employeeDetails');
    }

  }




  email = (val) => {
    this.setState({ email: val.target.value })
  }

  password = (val) => {
    this.setState({ password: val.target.value })
  }

  submit = (event) => {
    db.collection("companyUsers").where('email', '==', this.state.email).where('password', '==', this.state.password)
      .get()
      .then((querySnapshot) => {


        if (!querySnapshot.size > 0) {
          alert('Email or Password are wrong!!!')
        }

        querySnapshot.docs.map((documentSnapshot) => {
          cookie.save('login', documentSnapshot.data(), { path: '/' })
          this.props.history.push({
            pathname: '/employeeDetails',
            search: '',
            state: { login: documentSnapshot.data() }
          })
          //this.props.history.push('/dashboard',documentSnapshot.data());
        });



      })
      .catch(function (error) {
        console.log("Error getting documents: ", error);
      });



    event.preventDefault()

  }


  saveUser = async () => {
    return;
    // try {
    //     await Auth.signIn(this.state.email, this.state.password);
    //     this.props.userHasAuthenticated(true);
    //     this.props.history.push("/");
    //   } catch (e) {
    //     alert(e.message);
    //   }

  }

  render() {
    return (
      <div style={{ backgroundColor: '#334274', height: '100vh' }}>

        <div class="container">

          <div class="row justify-content-center">

            <div class="col-xl-10 col-lg-12 col-md-9">

              <div class="card o-hidden border-0 shadow-lg my-5">
                <div class="card-body p-0">
                  <div class="row">
                    <div class="col-lg-6 d-none d-lg-block bg-login-image"></div>
                    <div class="col-lg-6">
                      <div class="p-5">
                        <div class="text-center">
                          <img src="img/lawindz.png" />
                          <h1 class="h4 text-gray-900 mb-4">Canteen Software</h1>
                        </div>
                        <form class="user" onSubmit={this.submit}>
                          <div class="form-group">
                            <input type="email" class="form-control form-control-user" id="exampleInputEmail" aria-describedby="emailHelp" placeholder="Enter Email Address" value={this.state.email} onChange={this.email} />
                          </div>
                          <div class="form-group">
                            <input type="password" onChange={this.password} value={this.state.password} class="form-control form-control-user" id="exampleInputPassword" placeholder="Enter Password" />
                          </div>

                          <a onClick={this.submit} class="btn btn-primary btn-user btn-block">
                            Login
                  </a>
                        </form>
                        <hr />
                        <div class="text-center">
                          <a class="small" href="forgot-password.html">Forgot Password?</a>
                        </div>

                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>

          </div>

        </div>
      </div>

    )



  }



}


// <div class="form-group">


// <input type="email" value={this.state.email} onChange={this.email} class="form-control" id="inputEmail" placeholder="Email Address"  />

// </div>

// <div class="form-group">

//  <input type="password" class="form-control" id="inputPassword" placeholder="Password" />

// </div>