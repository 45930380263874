import React, { Component } from "react";
import firebase from "./Firebase";
import { Link } from "react-router-dom";
import {
  BootstrapTable,
  TableHeaderColumn,
  InsertModalFooter,
  InsertButton
} from "react-bootstrap-table";
import { ExcelRenderer } from "react-excel-renderer";
import cookie from 'react-cookies'



let db = firebase.firestore();

let fileObj = undefined

export default class ImportExcel extends Component {
  constructor(props) {
    super(props);
    this.onAddRow = this.onAddRow.bind(this);
    this.insertEmployee = this.insertEmployee.bind(this);
    this.fileHandler = this.fileHandler.bind(this);
    this.state = {
      dummy: "",
      empList: [],
      loginUser: '', excelData: []
    };
  }

  componentDidMount() {

    const login = cookie.load('login', { path: '/' });



    if (login === undefined) {
      this.props.history.push('/');
      return;
    }
    this.setState({ loginUser: JSON.parse(login) })



    // db.collection("employees").doc(this.state.loginUser.name).collection('data')
    //     .get()
    //     .then((querySnapshot) => {

    //         let data = querySnapshot.docs.map(function (documentSnapshot) {

    //             return documentSnapshot.data();
    //         });

    //         this.setState({ empList: data })


    //     })
    //     .catch(function (error) {
    //         console.log("Error getting documents: ", error);
    //     });

  }

  logout = () => {
    cookie.remove('login', { path: '/' })
    this.props.history.push('/')
  }

  fileHandler = event => {
    fileObj = event.target.files[0];


  };

  submit = () => {

    if (fileObj === undefined || fileObj === null) {
      alert('Please select csv file')
      return;
    }
    //just pass the fileObj as parameter
    ExcelRenderer(fileObj, (err, resp) => {
      if (err) {
        console.log(err);
      } else {
        let array = undefined;
        this.setState(
          {
            excelData: [],
            cols: resp.cols,
            rows: resp.rows
          },
          () => {
            this.state.rows.forEach(element => {
              if (array === undefined) {
                array = element;
                return;
              }
              let obj = Object.assign(
                {},
                ...array.map((n, index) => ({ [n]: element[index] }))
              );
              this.state.excelData.push(obj);
            });


            let data = this.state.excelData.map((documentSnapshot) => {
              return {
                ...documentSnapshot,
                emp_id: this.state.loginUser.companyId + "_" + documentSnapshot.emp_id,
                'download': "Download",
                'subcidyValue': documentSnapshot.subcidyValue !== undefined && documentSnapshot.subcidyValue !== '' ? documentSnapshot.subcidyValue : '0',
                'company': this.state.loginUser.name.toUpperCase(),
                'is_active': true,
              }

            });



            this.setState({ dummy: "" }, () => {

              let batch = db.batch();

              data.forEach(doc => {
                batch.set(db.collection("employees").doc(this.state.loginUser.name).collection('data').doc(), doc);
              });

              batch.commit().then(() => {
                alert('success')
                this.setState({ empList: [] }, () => {

                  db.collection("employees").doc(this.state.loginUser.name).collection('data')
                    .get()
                    .then((querySnapshot) => {

                      let data = querySnapshot.docs.map(function (documentSnapshot) {

                        return documentSnapshot.data();
                      });

                      this.setState({ empList: data })


                    })
                    .catch(function (error) {
                      console.log("Error getting documents: ", error);
                    });
                })
              });
            });
          }
        );
      }
    });

  }

  mainDashboard = () => {
    this.props.history.push("/mainDashboard");
  };

  employeeDetails = () => {
    this.props.history.push("/employeeDetails");
  };

  companyDetails = () => {
    this.props.history.push("/companyDetails");
  };

  foodTransaction = () => {
    this.props.history.push("/foodTransaction");
  };

  onAddRow = row => {
    if (
      row.position !== "" &&
      row.emp_id !== "" &&
      row.emp_mobile !== "" &&
      row.emp_name !== ""
    ) {
      this.insertEmployee(row);
    } else {
      alert("Please Fill All The Fields");
    }
  };

  insertEmployee = row => {
    this.setState({ qrcodeValue: row.emp_id }, () => {

      db.collection("employees").doc(this.state.loginUser.name).collection('data')
        .add({
          'emp_id':
            this.state.loginUser.companyId + "_" + row.emp_id.toUpperCase(),
          'emp_name': row.emp_name.toUpperCase(),
          'company': this.state.loginUser.name.toUpperCase(),
          'position': row.position.toUpperCase(),
          // 'pd': row.pd.toUpperCase(),
          'emp_mobile': row.emp_mobile,
          'is_active': true,
          'download': "Download",
          'subcidyValue': row.subcidyValue !== '' ? row.subcidyValue : '0'

        })
        .then(docRef => {
          this.state.empList.push({
            'emp_id':
              this.state.loginUser.companyId + "_" + row.emp_id.toUpperCase(),
            'emp_name': row.emp_name.toUpperCase(),
            'company': this.state.loginUser.name.toUpperCase(),
            'position': row.position.toUpperCase(),
            // 'pd': row.pd.toUpperCase(),
            'emp_mobile': row.emp_mobile,
            'is_active': true,
            'download': "Download",
            'subcidyValue': row.subcidyValue !== '' ? row.subcidyValue : '0'

          });
          this.setState({ qrcodeValue: "" });
        })
        .catch(error => {
          alert("error");
          console.error("Error adding document: ", error);
        });
    });
  };

  render() {
    const options = {
      onAddRow: row => this.onAddRow(row),
      paginationSize: 20,
      paginationShowsTotal: true,
      searchDelayTime: 500,
      sizePerPage: 20
    };



    return (
      <div id="wrapper">
        <ul
          class="navbar-nav bg-gradient-primary sidebar sidebar-dark accordion"
          id="accordionSidebar"
        >
          <a
            class="sidebar-brand d-flex align-items-center justify-content-center"
            href="index.html"
          >
            <div class="sidebar-brand-icon rotate-n-15">
              <i class="fas fa-laugh-wink"></i>
            </div>
            <div class="sidebar-brand-text mx-3">
              {this.state.loginUser !== undefined
                ? this.state.loginUser.name
                : ""}{" "}
              Admin{" "}
            </div>
          </a>

          <hr class="sidebar-divider my-0"></hr>

          <li class="nav-item active">
            <a class="nav-link" onClick={this.mainDashboard}>
              <i class="fas fa-fw fa-tachometer-alt"></i>
              <span>Dashboard</span>
            </a>
          </li>

          <hr class="sidebar-divider" />

          <div class="sidebar-heading">Interface</div>

          <li class="nav-item active">
            <a class="nav-link" onClick={this.companyDetails}>
              <i class="fas fa-fw fa-chart-area"></i>
              <span>Companys Details</span>
            </a>
          </li>

          <li class="nav-item active">
            <a class="nav-link" onClick={this.employeeDetails}>
              <i class="fas fa-fw fa-table"></i>
              <span>Employee Details</span>
            </a>
          </li>

          <li class="nav-item active">
            <a class="nav-link" onClick={this.foodTransaction}>
              <i class="fas fa-fw fa-table"></i>
              <span>Food Transaction</span>
            </a>
          </li>

          <li class="nav-item active">
            <a class="nav-link" onClick={this.logout}>
              <i class="fas fa-sign-out-alt"></i>
              <span>logout</span></a>
          </li>


          <hr class="sidebar-divider d-none d-md-block"></hr>

          <div class="text-center d-none d-md-inline">
            <button
              ref={a => (this.button = a)}
              class="rounded-circle border-0"
              id="sidebarToggle"
            ></button>
          </div>
        </ul>

        <div class="container-fluid">
          <div class="card shadow mb-4">
            <div class="card-header py-3">
              <h6 class="m-0 font-weight-bold text-primary float-left">
                Employee Details
              </h6>
              <div class="d-none d-sm-inline-block btn btn-sm btn-primary shadow-sm float-right">

                <button style={{ padding: "2px" }}
                  onClick={this.submit} >
                  submit
                 </button>

                <input
                  type="file"
                  onChange={this.fileHandler}
                  style={{ padding: "10px" }}
                />
              </div>
            </div>
            <div class="card-body">
              <div class="table-responsive">
                <BootstrapTable
                  data={this.state.empList}
                  options={options}
                  exportCSV={true}
                  search={true}
                  striped={true}
                  bordered={true}
                  hover={true}
                  pagination={true}
                >
                  <TableHeaderColumn
                    width={"15%"}
                    dataField="emp_id"
                    isKey={true}
                  >
                    Employee ID
                  </TableHeaderColumn>
                  <TableHeaderColumn width={"15%"} dataField="emp_name">
                    Name
                  </TableHeaderColumn>
                  <TableHeaderColumn width={"15%"} dataField="position">
                    Position
                  </TableHeaderColumn>
                  {/* <TableHeaderColumn
                    width={"15%"}
                    dataField="pd"
                    editable={{ type: "select", options: { values: pd } }}
                  >
                    Payment Details
                  </TableHeaderColumn> */}
                  <TableHeaderColumn width={'15%'} dataField="subcidyValue"   >Subcidy Value</TableHeaderColumn>
                  <TableHeaderColumn width={"15%"} dataField="emp_mobile">
                    Mobile Number
                  </TableHeaderColumn>
                  {/* <TableHeaderColumn width={"15%"} dataField="download">
                    Download QR
                  </TableHeaderColumn> */}
                </BootstrapTable>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
