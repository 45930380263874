import React , { Component } from 'react';
import './App.css';
import {
  BrowserRouter as Router,
  Route,
} from 'react-router-dom';
import Dashboard from './Components/Dashboard'
import ImportExcel from './Components/ImportExcel'
import Login from './Components/Login'
import MainDashboard from './Components/MainDashboard'
import CompanyDetails from './Components/CompanyDetails'
import FoodTransaction from './Components/FoodTransaction'
import FoodTransactionMonthly from './Components/FoodTransactionMonthly'



class App extends Component {
  render() {
    return (
      <Router>
          <Route  path="/employeeDetails" component={Dashboard} />
          <Route exact path="/importExcel" component={ImportExcel} />
          <Route exact path="/" component={Login} />
          <Route exact path="/mainDashboard" component={MainDashboard} />
          <Route exact path="/companyDetails" component={CompanyDetails} />
          <Route exact path="/foodTransaction" component={FoodTransaction} />
          <Route exact path="/foodTransactionMonthly" component={FoodTransactionMonthly} />
      </Router>
    )
  }
}


export default App;
