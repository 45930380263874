import React, { Component } from 'react'
import firebase from './Firebase'
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import Modal from 'react-modal'
// import {
//     BrowserRouter as Router,
//     Route,
//     Link
//   } from 'react-router-dom';
import cookie from 'react-cookies'
import moment from 'moment'
import { DateRange } from 'react-date-range';
import Calendar from 'react-calendar';



let db = firebase.firestore()



const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)'
    }
};


export default class FoodTransaction extends Component {

    constructor(props) {
        super(props)

        this.openModal = this.openModal.bind(this);
        this.afterOpenModal = this.afterOpenModal.bind(this);
        this.closeModal = this.closeModal.bind(this);

        this.state = {
            qrcodeValue: '',
            foodTransaction: [{
                'emp_id': '',
                'type': '',
                'time': '',

            }],
            loginUser: undefined,
            date: '',
            modalIsOpen: false,
            calDate: new Date(),
            teaCount: 0,
            lunchCount: 0,
            breakFastCount: 0,
            dinnerCount: 0,
            startDate :new Date(),
            endDate : new Date()
        }
    }

    componentDidMount() {


        const login = cookie.load('login', { path: '/' });



        if (login === undefined) {
            this.props.history.push('/');
            return;
        }


        if (this.props.location.data !== undefined) {



            this.setState({ loginUser: JSON.parse(login) }, () => {


                this.setState({ date: this.getConversitionDate(new Date()), foodTransaction: [] }, () => {



                    db.collection("food").doc("" + this.state.date).collection(this.state.loginUser.name).where('emp_id', '==', this.props.location.data)
                        .get()
                        .then((querySnapshot) => {

                            let data = querySnapshot.docs.map((documentSnapshot) => {
                                return {
                                    ...documentSnapshot.data(),
                                    time: moment(documentSnapshot.data().time.toDate()).format('hh:mm A')
                                }
                            });

                            this.setState({ foodTransaction: data }, () => {
                                this.countData()
                            })


                        })
                        .catch((error) => {
                            alert('error')
                            console.log("Error getting documents: ", error);
                        });



                })






            })







        } else {



            this.setState({ loginUser: JSON.parse(login) }, () => {


                this.setState({ date: this.getConversitionDate(new Date()), foodTransaction: [] }, () => {



                    db.collection("food").doc("" + this.state.date).collection(this.state.loginUser.name)
                        .get()
                        .then((querySnapshot) => {

                            let data = querySnapshot.docs.map((documentSnapshot) => {
                                return {
                                    ...documentSnapshot.data(),
                                    time: moment(documentSnapshot.data().time.toDate()).format('hh:mm A')
                                }
                            });

                            this.setState({ foodTransaction: data }, () => {
                                this.countData()
                            })


                        })
                        .catch((error) => {
                            alert('error')
                            console.log("Error getting documents: ", error);
                        });



                })






            })

        }






    }


    countData = () => {


        const foodTransaction = this.state.foodTransaction
        this.setState({
            lunchCount: foodTransaction.filter(x => x.type === 'lunch').length, breakFastCount: foodTransaction.filter(x => x.type === 'breakFast').length,
            dinnerCount: foodTransaction.filter(x => x.type === 'dinner').length, teaCount: foodTransaction.filter(x => x.type === 'tea').length
        })




    }

    getConversitionDate = (d) => {
        var today = d;
        var dd = String(today.getDate()).padStart(2, '0');
        var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
        var yyyy = today.getFullYear();
        today = dd + '-' + mm + '-' + yyyy;
        return today
    }

    mainDashboard = () => {
        this.props.history.push('/mainDashboard');
    }
    logout = () => {
        cookie.remove('login', { path: '/' })
        this.props.history.push('/')
    }

    employeeDetails = () => {
        this.props.history.push('/employeeDetails');
    }

    companyDetails = () => {
        this.props.history.push('/companyDetails');
    }

    foodTransaction = () => {
        this.props.history.push('/foodTransaction')
    }

    foodTransactionMonthly = () => {
        this.props.history.push('/foodTransactionMonthly')

    }

    calButton = () => {
        this.setState({ modalIsOpen: !this.state.modalIsOpen })

    }

    openModal() {
        this.setState({ modalIsOpen: true });
    }

    afterOpenModal() {
        // references are now sync'd and can be accessed.
        //this.subtitle.style.color = '#f00';
    }

    closeModal() {
        this.setState({ modalIsOpen: false });
    }

    onChange = date => {
        this.setState({ calDate: date, modalIsOpen: false, date: this.getConversitionDate(date), foodTransaction: [] }, () => {


            if (this.props.location.data !== undefined) {


                db.collection("food").doc("" + this.state.date).collection(this.state.loginUser.name).where('emp_id', '==', this.props.location.data)
                    .get()
                    .then((querySnapshot) => {

                        let data = querySnapshot.docs.map((documentSnapshot) => {
                            return {
                                ...documentSnapshot.data(),
                                time: moment(documentSnapshot.data().time.toDate()).format('hh:mm A')
                            }
                        });

                        this.setState({ foodTransaction: data }, () => {
                            this.countData()
                        })


                    })
                    .catch((error) => {
                        alert('error')
                        console.log("Error getting documents: ", error);
                    });





            } else {



                db.collection("food").doc("" + this.state.date).collection(this.state.loginUser.name)
                    .get()
                    .then((querySnapshot) => {

                        let data = querySnapshot.docs.map((documentSnapshot) => {
                            return {
                                ...documentSnapshot.data(),
                                time: moment(documentSnapshot.data().time.toDate()).format('hh:mm A')
                            }

                        });

                        this.setState({ foodTransaction: data }, () => {
                            this.countData()
                        })


                    })
                    .catch((error) => {
                        alert('error')
                        console.log("Error getting documents: ", error);
                    });




            }
        })

    }

    handleSelect(range){
        console.log(range);
        // An object with two keys,
        // 'startDate' and 'endDate' which are Momentjs objects.
    }


    render() {
        const options = {
            paginationSize: 50,
            paginationShowsTotal: true,
            searchDelayTime: 500,
            sizePerPage: 50,
        }



        return (
            <div id="wrapper" >


                <ul class="navbar-nav bg-gradient-primary sidebar sidebar-dark accordion" id="accordionSidebar">

                    <a class="sidebar-brand d-flex align-items-center justify-content-center" href="index.html">
                        <div class="sidebar-brand-icon rotate-n-15">
                            <i class="fas fa-laugh-wink"></i>
                        </div>
                        <div class="sidebar-brand-text mx-3">{this.state.loginUser !== undefined ? this.state.loginUser.name : ''} Admin </div>
                    </a>

                    <hr class="sidebar-divider my-0"></hr>

                    <li class="nav-item active">
                        <a class="nav-link" onClick={this.mainDashboard}>
                            <i class="fas fa-fw fa-tachometer-alt"></i>
                            <span>Dashboard</span></a>
                    </li>

                    <hr class="sidebar-divider" />

                    <div class="sidebar-heading">
                        Interface
</div>

                    <li class="nav-item active">
                        <a class="nav-link" onClick={this.companyDetails}>
                            <i class="fas fa-fw fa-chart-area"></i>
                            <span>Companys Details</span></a>
                    </li>

                    <li class="nav-item active">
                        <a class="nav-link" onClick={this.employeeDetails}>
                            <i class="fas fa-fw fa-table"></i>
                            <span>Employee Details</span></a>
                    </li>

                    <li class="nav-item active">
                        <a class="nav-link" onClick={this.foodTransaction}>
                            <i class="fas fa-fw fa-table"></i>
                            <span>Food Transaction</span></a>
                    </li>

                    <li class="nav-item active">
                        <a class="nav-link" onClick={this.foodTransactionMonthly}>
                            <i class="fas fa-fw fa-table"></i>
                            <span>Food Transaction Monthly</span></a>
                    </li>

                    <li class="nav-item active">
                        <a class="nav-link" onClick={this.logout}>
                            <i class="fas fa-sign-out-alt"></i>
                            <span>logout</span></a>
                    </li>


                    <hr class="sidebar-divider d-none d-md-block"></hr>

                    <div class="text-center d-none d-md-inline">
                        <button ref={a => this.button = a} class="rounded-circle border-0" id="sidebarToggle"></button>
                    </div>


                </ul>
                <div class="container-fluid">
                    <div class="card shadow mb-4">
                        <div class="card-header py-3">
                            <h6 class="m-0 font-weight-bold text-primary float-left">Food Transaction {this.state.date}</h6>

                            <div onClick={this.importExcel} class="d-none d-sm-inline-block btn btn-sm btn-primary shadow-sm float-right"><i class="fas fa-plus fa-sm text-white-50"></i> import from excel</div>

                            <div id={'123'} onClick={this.calButton} style={{ marginLeft: 5, marginRight: 5 }} class="d-none d-sm-inline-block btn btn-sm btn-primary shadow-sm float-right"><i class="fas fa-plus fa-sm text-white-50"></i> Calendar Button</div>


                        </div>



                        <div class="row" style={{ marginTop: 20, marginLeft: 20 }}>

                            <div class="col-xl-3 col-md-6 mb-4">
                                <div class="card border-left-primary shadow h-100 py-2">
                                    <div class="card-body">
                                        <div class="row no-gutters align-items-center">
                                            <div class="col mr-2">
                                                <div class="text-xs font-weight-bold text-primary text-uppercase mb-1">Breakfast</div>
                                                <div class="h5 mb-0 font-weight-bold text-gray-800">{this.state.breakFastCount}</div>
                                            </div>
                                            <div class="col-auto">
                                                <i class="fas fa-calendar fa-2x text-gray-300"></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div class="col-xl-3 col-md-6 mb-4">
                                <div class="card border-left-success shadow h-100 py-2">
                                    <div class="card-body">
                                        <div class="row no-gutters align-items-center">
                                            <div class="col mr-2">
                                                <div class="text-xs font-weight-bold text-success text-uppercase mb-1">Lunch</div>
                                                <div class="h5 mb-0 font-weight-bold text-gray-800">{this.state.lunchCount}</div>
                                            </div>
                                            <div class="col-auto">
                                                <i class="fas fa-dollar-sign fa-2x text-gray-300"></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-xl-3 col-md-6 mb-4">
                                <div class="card border-left-info shadow h-100 py-2">
                                    <div class="card-body">
                                        <div class="row no-gutters align-items-center">
                                            <div class="col mr-2">
                                                <div class="text-xs font-weight-bold text-info text-uppercase mb-1">Dinner</div>
                                                <div class="row no-gutters align-items-center">
                                                    <div class="col-auto">
                                                        <div class="h5 mb-0 mr-3 font-weight-bold text-gray-800">{this.state.dinnerCount}</div>
                                                    </div>

                                                </div>
                                            </div>
                                            <div class="col-auto">
                                                <i class="fas fa-clipboard-list fa-2x text-gray-300"></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-xl-3 col-md-6 mb-4">
                                <div class="card border-left-warning shadow h-100 py-2">
                                    <div class="card-body">
                                        <div class="row no-gutters align-items-center">
                                            <div class="col mr-2">
                                                <div class="text-xs font-weight-bold text-warning text-uppercase mb-1">Tea</div>
                                                <div class="h5 mb-0 font-weight-bold text-gray-800">{this.state.teaCount}</div>
                                            </div>
                                            <div class="col-auto">
                                                <i class="fas fa-comments fa-2x text-gray-300"></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>






                        </div>













                        <div class="card-body">

                            {this.state.modalIsOpen && <div style={{ margin: 50 }}
                            >



                                <Modal
                                    appElement={document.getElementById('123')}

                                    isOpen={this.state.modalIsOpen}
                                    onAfterOpen={this.afterOpenModal}
                                    onRequestClose={this.closeModal}
                                    style={customStyles}
                                    contentLabel="Example Modal"
                                >
                                    <h2 >Select Date</h2>

                                    




                                     <Calendar
                                        maxDate={new Date()}
                                        onChange={this.onChange}
                                        value={this.state.calDate}
                                    /> 

                                </Modal>


                            </div>}
                            <div class="table-responsive">

                                <BootstrapTable data={this.state.foodTransaction} options={options} exportCSV={true} search={true} striped={true} bordered={true} hover={true} pagination={true}>
                                    <TableHeaderColumn width={'15%'} dataField="emp_id" isKey={true}  >Employee ID</TableHeaderColumn>
                                    <TableHeaderColumn width={'15%'} dataField="type" >Type</TableHeaderColumn>
                                    <TableHeaderColumn width={'15%'} dataField="time" >Time</TableHeaderColumn>

                                </BootstrapTable>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
