import React, { Component } from 'react'
import firebase from './Firebase'
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import cookie from 'react-cookies'



let db = firebase.firestore()

export default class CompanyDetails extends Component {

    constructor(props) {
        super(props)
        this.onAddRow = this.onAddRow.bind(this)

        this.state = {
            qrcodeValue: '',
            loginUser: undefined,
            companyList: []
        }
    }

    componentDidMount() {

        const login = cookie.load('login', { path: '/' });



        if (login === undefined) {
            this.props.history.push('/');
            return;
        }
        this.setState({ loginUser: JSON.parse(login) }, () => {






            db.collection("companyProfiles")
                .get()
                .then((querySnapshot) => {

                    let data = querySnapshot.docs.map(function (documentSnapshot) {

                        return documentSnapshot.data();
                    });


                    if (this.state.loginUser !== undefined && this.state.loginUser !== null && this.state.loginUser.superadmin !== undefined &&
                        this.state.loginUser.superadmin) {
                        this.setState({ companyList: data })

                    } else {
                        let obj = data.find(v => v.companyId === this.state.loginUser.companyId)
                        if (obj) {
                            this.setState({ companyList: this.state.companyList.concat(obj) })
                        }
                    }



                })
                .catch(function (error) {
                    console.log("Error getting documents: ", error);
                });









        })

    }

    onRowClick = (row, columnIndex, rowIndex) => {



    }
    logout = () => {
        cookie.remove('login', { path: '/' })
        this.props.history.push('/')
    }

    onAddRow = (row) => {
        if (row.Name !== "" && row.PAN !== "" && row.GST !== "" &&
            row.companyId !== "" && row.Address !== "") {
            this.insertEmployee(row)
        } else {
            alert('Please Fill All The Fields')
        }

    }

    insertEmployee = (row) => {
        db.collection("companyProfiles").add({
            'companyId': row.companyId.toUpperCase(),
            'Name': row.Name.toUpperCase(),
            'PAN': row.PAN.toUpperCase(),
            'GST': row.GST.toUpperCase(),
            'Address': row.Address.toUpperCase(),
            'is_active': true,
        })
            .then((docRef) => {

                db.collection("companyUsers").add({
                    'email': 'admin@' + row.Name.toLowerCase()+".com",
                    'password': '12345678',
                    'name':  row.Name.toUpperCase(),
                    'companyId': row.companyId.toUpperCase(),
                }).then((doc) => {
                    this.state.companyList.push({
                        'companyId': row.companyId.toUpperCase(),
                        'Name': row.Name.toUpperCase(),
                        'PAN': row.PAN.toUpperCase(),
                        'GST': row.GST.toUpperCase(),
                        'Address': row.Address.toUpperCase(),
                        'is_active': true,
                    })

                    this.setState({companyList : this.state.companyList})


                }).catch((error) => {
                    alert('error')
                    console.error("Error adding document: ", error);
                });


            })
            .catch((error) => {
                alert('error')
                console.error("Error adding document: ", error);
            });

    }

    mainDashboard = () => {
        this.props.history.push('/mainDashboard');
    }

    employeeDetails = () => {
        this.props.history.push('/employeeDetails');
    }

    companyDetails = () => {
        this.props.history.push('/companyDetails');
    }

    foodTransaction = () => {
        this.props.history.push('/foodTransaction')
    }

    foodTransactionMonthly = () => {
        this.props.history.push('/foodTransactionMonthly')

    }


    render() {
        const options = {
            onAddRow: (row) => this.onAddRow(row),
            onRowClick: (row, columnIndex, rowIndex) => this.onRowClick(row, columnIndex, rowIndex),
            paginationSize: 20,
            paginationShowsTotal: true,
            searchDelayTime: 500,
            sizePerPage: 20,
        }

        return (

            <div id="wrapper">


                <ul class="navbar-nav bg-gradient-primary sidebar sidebar-dark accordion" id="accordionSidebar">

                    <a class="sidebar-brand d-flex align-items-center justify-content-center" href="index.html">
                        <div class="sidebar-brand-icon rotate-n-15">
                            <i class="fas fa-laugh-wink"></i>
                        </div>
                        <div class="sidebar-brand-text mx-3">{this.state.loginUser !== undefined ? this.state.loginUser.name : ''} Admin </div>
                    </a>

                    <hr class="sidebar-divider my-0"></hr>

                    <li class="nav-item active">
                        <a class="nav-link" onClick={this.mainDashboard}>
                            <i class="fas fa-fw fa-tachometer-alt"></i>
                            <span>Dashboard</span></a>
                    </li>

                    <hr class="sidebar-divider" />

                    <div class="sidebar-heading">
                        Interface
</div>

                    <li class="nav-item active">
                        <a class="nav-link" onClick={this.companyDetails}>
                            <i class="fas fa-fw fa-chart-area"></i>
                            <span>Companys Details</span></a>
                    </li>

                    <li class="nav-item active">
                        <a class="nav-link" onClick={this.employeeDetails}>
                            <i class="fas fa-fw fa-table"></i>
                            <span>Employee Details</span></a>
                    </li>

                    <li class="nav-item active">
                        <a class="nav-link" onClick={this.foodTransaction}>
                            <i class="fas fa-fw fa-table"></i>
                            <span>Food Transaction</span></a>
                    </li>

                    <li class="nav-item active">
                        <a class="nav-link" onClick={this.foodTransactionMonthly}>
                            <i class="fas fa-fw fa-table"></i>
                            <span>Food Transaction Monthly</span></a>
                    </li>

                    <li class="nav-item active">
                        <a class="nav-link" onClick={this.logout}>
                            <i class="fas fa-sign-out-alt"></i>
                            <span>logout</span></a>
                    </li>


                    <hr class="sidebar-divider d-none d-md-block"></hr>

                    <div class="text-center d-none d-md-inline">
                        <button ref={a => this.button = a} class="rounded-circle border-0" id="sidebarToggle"></button>
                    </div>


                </ul>

                <div class="container-fluid">
                    <div class="card-body">
                        <div class="table-responsive">

                            <BootstrapTable data={this.state.companyList} options={options} exportCSV={true} insertRow={this.state.loginUser !== undefined && this.state.loginUser !== null && this.state.loginUser.superadmin !== undefined &&
                                this.state.loginUser.superadmin ? true : false} search={true} striped={true} bordered={true} hover={true} pagination={true}>
                                <TableHeaderColumn width={'15%'} dataField="companyId" isKey={true}  >Company ID</TableHeaderColumn>
                                <TableHeaderColumn width={'15%'} dataField="Name" >Name</TableHeaderColumn>
                                <TableHeaderColumn width={'15%'} dataField="PAN" >PAN</TableHeaderColumn>
                                <TableHeaderColumn width={'15%'} dataField="GST" >GST</TableHeaderColumn>
                                <TableHeaderColumn width={'15%'} dataField="Address" >Address</TableHeaderColumn>

                            </BootstrapTable>


                        </div>
                    </div>
                </div>
            </div>
        )
    }





}    