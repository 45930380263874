import React, { Component } from 'react'
import firebase from './Firebase'
import { BootstrapTable, TableHeaderColumn, InsertModalFooter, InsertButton } from 'react-bootstrap-table';
import {
  BrowserRouter as Router,
  Route,
  Link
} from 'react-router-dom';

import cookie from 'react-cookies'
import moment from 'moment'

import DatePicker from 'react-datepicker'
import Calendar from 'react-calendar';

import Modal from 'react-modal'



import Select from 'react-select';



let db = firebase.firestore()


const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)'
  }
};



export default class Dashboard extends Component {

  constructor(props) {
    super(props)

    this.state = {
      qrcodeValue: '',
      loginUser: undefined,
      companyList: [],
      date: '',
      modalIsOpen: false,
      calDate: new Date(),
      teaCount: 0,
      lunchCount: 0,
      breakFastCount: 0,
      dinnerCount: 0,
      startDate: new Date(),
      endDate: new Date(),
      monthDate: '',
      startMonth: new Date(),
      totalEmployees: 0,
      activeEmployees: 0,
      InActiveEmployees: 0,
      superadmin: false,
      allProfiles: [],
      selectedAdmin : '',
      selectedAdminValue: ''

    }
  }

  componentDidMount() {

    const login = cookie.load('login', { path: '/' });




    if (login === undefined) {
      this.props.history.push('/');
      return;
    }





    this.setState({ loginUser: JSON.parse(login), date: this.getConversitionDate(new Date()), foodTransaction: [] }, () => {



      if (this.state.loginUser.superadmin) {

        db.collection("companyUsers")
          .get()
          .then((querySnapshot) => {

            let array = []
            querySnapshot.docs.map((documentSnapshot) => {

              if ("lawindz" !== documentSnapshot.data().name) {
                let obj = {
                  'value': documentSnapshot.data().name,
                  'label': documentSnapshot.data().name
                }
                array.push(obj)
              }

            });

            this.setState({ allProfiles: array , selectedAdmin : array.length > 0 ? array[0].label : '' })





          })



      }





      db.collection("companyProfiles")
        .get()
        .then((querySnapshot) => {

          let data = querySnapshot.docs.map(function (documentSnapshot) {

            return documentSnapshot.data();
          });




          if (this.state.loginUser !== undefined && this.state.loginUser !== null && this.state.loginUser.superadmin !== undefined &&
            this.state.loginUser.superadmin) {
            this.setState({ companyList: data })

          } else {
            let obj = data.find(v => v.companyId === this.state.loginUser.companyId)
            if (obj) {
              this.setState({ companyList: this.state.companyList.concat(obj) })
            }
          }


        })
        .catch(function (error) {
          console.log("Error getting documents: ", error);
        });



        if(this.state.selectedAdmin === ""){
          this.state.selectedAdmin = this.state.loginUser.name
        }



      db.collection("foodMonth").doc("" + this.getConversitionMonth(new Date())).collection(this.state.loginUser.superadmin ? this.state.selectedAdmin : this.state.loginUser.name)
        .get()
        .then((querySnapshot) => {

          let array = querySnapshot.docs.map((documentSnapshot) => {
            return {
              ...documentSnapshot.data(),
              time: moment(documentSnapshot.data().time.toDate()).format('hh:mm A')
            }
          });

          let checkList = []

          let monthlyData = []

          let obj = {}

          array.forEach(item => {

            if (!checkList.includes(item.emp_id)) {
              checkList.push(item.emp_id)
              obj = {
                'tea': array.filter(x => x.type === 'tea' && x.emp_id === item.emp_id).length,
                'lunch': array.filter(x => x.type === 'lunch' && x.emp_id === item.emp_id).length,
                'dinner': array.filter(x => x.type === 'dinner' && x.emp_id === item.emp_id).length,
                'breakFast': array.filter(x => x.type === 'breakFast' && x.emp_id === item.emp_id).length,
                'emp_id': item.emp_id
              }
              monthlyData.push(obj)
            }
          })

          this.setState({ foodTransaction: monthlyData }, () => {
            this.countData()
          })





        })
        .catch((error) => {
          alert('error')
          console.log("Error getting documents: ", error);
        });





      this.getDataEmployeesData()










    })

  }

  getDataEmployeesData = () => {



    db.collection("employees").doc(this.state.loginUser.superadmin ? this.state.selectedAdmin : this.state.loginUser.name).collection('data')
      .get()
      .then((querySnapshot) => {
        let data = querySnapshot.docs.map(function (documentSnapshot) {
          return documentSnapshot.data();
        });
        this.setState({ empList: data, totalEmployees: data.length, activeEmployees: data.length, InActiveEmployees: 0 })
      })
      .catch((error) => {
        console.log("Error getting documents: ", error);
      });


  }

  countData = () => {


    const foodTransaction = this.state.foodTransaction

    let lunch = 0, breakFast = 0, dinner = 0, tea = 0;

    foodTransaction.forEach(item => {

      lunch = lunch + item.lunch
      breakFast = breakFast + item.breakFast
      dinner = dinner + item.dinner
      tea = tea + item.tea



    })

    this.setState({
      lunchCount: lunch, breakFastCount: breakFast,
      dinnerCount: dinner, teaCount: tea
    })




  }


  openModal() {
    this.setState({ modalIsOpen: true });
  }

  afterOpenModal() {
    // references are now sync'd and can be accessed.
    //this.subtitle.style.color = '#f00';
  }

  closeModal() {
    this.setState({ modalIsOpen: false });
  }



  getConversitionMonth = (d) => {
    var today = d;
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = today.getFullYear();
    today = mm + '-' + yyyy;
    this.setState({ monthDate: today })
    return today
  }

  getConversitionDate = (d) => {
    var today = d;
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = today.getFullYear();
    today = dd + '-' + mm + '-' + yyyy;
    return today
  }


  onChange = date => {
    this.setState({ calDate: date, modalIsOpen: false, date: this.getConversitionDate(date), foodTransaction: [] }, () => {
      db.collection("foodMonth").doc("" + this.getConversitionMonth(date)).collection(this.state.loginUser.superadmin ? this.state.selectedAdmin : this.state.loginUser.name)
        .get()
        .then((querySnapshot) => {

          let array = querySnapshot.docs.map((documentSnapshot) => {
            return {
              ...documentSnapshot.data(),
              time: moment(documentSnapshot.data().time.toDate()).format('hh:mm A')
            }
          });

          let checkList = []

          let monthlyData = []

          let obj = {}

          array.forEach(item => {

            if (!checkList.includes(item.emp_id)) {
              checkList.push(item.emp_id)
              obj = {
                'tea': array.filter(x => x.type === 'tea' && x.emp_id === item.emp_id).length,
                'lunch': array.filter(x => x.type === 'lunch' && x.emp_id === item.emp_id).length,
                'dinner': array.filter(x => x.type === 'dinner' && x.emp_id === item.emp_id).length,
                'breakFast': array.filter(x => x.type === 'breakFast' && x.emp_id === item.emp_id).length,
                'emp_id': item.emp_id
              }
              monthlyData.push(obj)
            }
          })

          this.setState({ foodTransaction: monthlyData }, () => {
            this.countData()
          })





        })
        .catch((error) => {
          alert('error')
          console.log("Error getting documents: ", error);
        });




    })

  }









  calButton = () => {
    this.setState({ modalIsOpen: !this.state.modalIsOpen })

  }

  mainDashboard = () => {
    this.props.history.push('/mainDashboard');
  }

  employeeDetails = () => {
    this.props.history.push('/employeeDetails');
  }

  companyDetails = () => {
    this.props.history.push('/companyDetails');
  }

  foodTransaction = () => {
    this.props.history.push('/foodTransaction')
  }

  foodTransactionMonthly = () => {
    this.props.history.push('/foodTransactionMonthly')

  }

  logout = () => {
    cookie.remove('login', { path: '/' })
    this.props.history.push('/')
  }


  handleChange = selectedOption => {
    this.setState({selectedAdmin : selectedOption.label , selectedAdminValue :selectedOption})
    this.componentDidMount()
  };


  render() {

    return (


      <div id="wrapper">


        <ul class="navbar-nav bg-gradient-primary sidebar sidebar-dark accordion" id="accordionSidebar">

          <a class="sidebar-brand d-flex align-items-center justify-content-center" href="index.html">
            <div class="sidebar-brand-icon rotate-n-15">
              <i class="fas fa-laugh-wink"></i>
            </div>
            <div class="sidebar-brand-text mx-3">{this.state.loginUser !== undefined ? this.state.loginUser.name : ''}  Admin </div>
          </a>

          <hr class="sidebar-divider my-0"></hr>

          <li class="nav-item active">
            <a class="nav-link" onClick={this.mainDashboard}>
              <i class="fas fa-fw fa-tachometer-alt"></i>
              <span>Dashboard</span></a>
          </li>

          <hr class="sidebar-divider" />

          <div class="sidebar-heading">
            Interface
</div>

          <li class="nav-item active">
            <a class="nav-link" onClick={this.companyDetails}>
              <i class="fas fa-fw fa-chart-area"></i>
              <span>Companys Details</span></a>
          </li>

          <li class="nav-item active">
            <a class="nav-link" onClick={this.employeeDetails}>
              <i class="fas fa-fw fa-table"></i>
              <span>Employee Details</span></a>
          </li>

          <li class="nav-item active">
            <a class="nav-link" onClick={this.foodTransaction}>
              <i class="fas fa-fw fa-table"></i>
              <span>Food Transaction</span></a>
          </li>


          <li class="nav-item active">
            <a class="nav-link" onClick={this.foodTransactionMonthly}>
              <i class="fas fa-fw fa-table"></i>
              <span>Food Transaction Monthly</span></a>
          </li>

          <li class="nav-item active">
            <a class="nav-link" onClick={this.logout}>
              <i class="fas fa-sign-out-alt"></i>
              <span>logout</span></a>
          </li>

          <hr class="sidebar-divider d-none d-md-block"></hr>

          <div class="text-center d-none d-md-inline">
            <button ref={a => this.button = a} class="rounded-circle border-0" id="sidebarToggle"></button>
          </div>


        </ul>


        <div id="content-wrapper" class="d-flex flex-column">



          <div id="content">




            <div class="container-fluid">





              <div class="d-sm-flex align-items-center justify-content-between mb-4">
                
                {this.state.loginUser !== undefined && !this.state.loginUser.superadmin && <h1 class="h3 mb-0 text-gray-800">{ this.state.companyList !== undefined && this.state.companyList.length > 0 && this.state.companyList[0].Name}</h1>}

               {this.state.loginUser !== undefined && this.state.loginUser.superadmin && this.state.allProfiles.length > 0 && <div style={{marginTop : 20 , height: 100, width: 350 }}>
                  <Select
                    value={this.state.selectedAdminValue}
                    onChange={this.handleChange}
                    options={this.state.allProfiles}
                  />
                </div>}

                <div class="dropdown float-right">



                  <div style={{ margin: 10 }}>
                    <h6 class="m-0 font-weight-bold text-primary float-left">Food Transaction {this.state.monthDate}</h6>


                    <div style={{ marginRight: 10, marginLeft: 10 }} onClick={this.calButton} class="d-none d-sm-inline-block btn btn-sm btn-primary shadow-sm float-right"><i class="fas fa-plus fa-sm text-white-50"></i> Month Calendar</div>


                  </div>
                </div>


              </div>

              <div class="row">

                <div class="col-xl-3 col-md-6 mb-4">
                  <div class="card border-left-primary shadow h-100 py-2">
                    <div class="card-body">
                      <div class="row no-gutters align-items-center">
                        <div class="col mr-2">
                          <div class="text-xs font-weight-bold text-primary text-uppercase mb-1">Breakfast</div>
                          <div class="h5 mb-0 font-weight-bold text-gray-800">{this.state.breakFastCount}</div>
                        </div>
                        <div class="col-auto">
                          <i class="fas fa-calendar fa-2x text-gray-300"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-xl-3 col-md-6 mb-4">
                  <div class="card border-left-success shadow h-100 py-2">
                    <div class="card-body">
                      <div class="row no-gutters align-items-center">
                        <div class="col mr-2">
                          <div class="text-xs font-weight-bold text-success text-uppercase mb-1">Lunch</div>
                          <div class="h5 mb-0 font-weight-bold text-gray-800">{this.state.lunchCount}</div>
                        </div>
                        <div class="col-auto">
                          <i class="fas fa-dollar-sign fa-2x text-gray-300"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-xl-3 col-md-6 mb-4">
                  <div class="card border-left-info shadow h-100 py-2">
                    <div class="card-body">
                      <div class="row no-gutters align-items-center">
                        <div class="col mr-2">
                          <div class="text-xs font-weight-bold text-info text-uppercase mb-1">Dinner</div>
                          <div class="row no-gutters align-items-center">
                            <div class="col-auto">
                              <div class="h5 mb-0 mr-3 font-weight-bold text-gray-800">{this.state.dinnerCount}</div>
                            </div>

                          </div>
                        </div>
                        <div class="col-auto">
                          <i class="fas fa-clipboard-list fa-2x text-gray-300"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-xl-3 col-md-6 mb-4">
                  <div class="card border-left-warning shadow h-100 py-2">
                    <div class="card-body">
                      <div class="row no-gutters align-items-center">
                        <div class="col mr-2">
                          <div class="text-xs font-weight-bold text-warning text-uppercase mb-1">Tea</div>
                          <div class="h5 mb-0 font-weight-bold text-gray-800">{this.state.teaCount}</div>
                        </div>
                        <div class="col-auto">
                          <i class="fas fa-comments fa-2x text-gray-300"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>


              <div class="row">
                <div class="col-lg-4 mb-4">
                  <div class="card bg-primary text-white shadow">
                    <div class="card-body">
                      Employees
                      <div class="h4 mb-0 font-weight-bold text-white">{this.state.totalEmployees}</div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-4 mb-4">
                  <div class="card bg-success text-white shadow">
                    <div class="card-body">
                      Active Employees
                      <div class="h4 mb-0 font-weight-bold text-white">{this.state.activeEmployees}</div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-4 mb-4">
                  <div class="card bg-info text-white shadow">
                    <div class="card-body">
                      Slots
                      <div class="h4 mb-0 font-weight-bold text-white">6</div>
                    </div>
                  </div>
                </div>

              </div>



              <div class="row">
                {/* 
                <div class="col-xl-8 col-lg-7">
                  <div class="card shadow mb-4">
                    <div class="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                      <h6 class="m-0 font-weight-bold text-primary">Earnings Overview</h6>
                      <div class="dropdown no-arrow">
                        <a class="dropdown-toggle" href="#" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                          <i class="fas fa-ellipsis-v fa-sm fa-fw text-gray-400"></i>
                        </a>
                        <div class="dropdown-menu dropdown-menu-right shadow animated--fade-in" aria-labelledby="dropdownMenuLink">
                          <div class="dropdown-header">Dropdown Header:</div>
                          <a class="dropdown-item" href="#">Action</a>
                          <a class="dropdown-item" href="#">Another action</a>
                          <div class="dropdown-divider"></div>
                          <a class="dropdown-item" href="#">Something else here</a>
                        </div>
                      </div>
                    </div>
                    <div class="card-body">
                      <div class="chart-area">
                        <canvas id="myAreaChart"></canvas>
                      </div>
                    </div>
                  </div>
                </div> */}
                {/* 
                <div class="col-xl-4 col-lg-5">
                  <div class="card shadow mb-4">
                    <div class="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                      <h6 class="m-0 font-weight-bold text-primary">Total Employees</h6>
                      <div class="dropdown no-arrow">
                        <a class="dropdown-toggle" href="#" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                          <i class="fas fa-ellipsis-v fa-sm fa-fw text-gray-400"></i>
                        </a>
                        <div class="dropdown-menu dropdown-menu-right shadow animated--fade-in" aria-labelledby="dropdownMenuLink">
                          <div class="dropdown-header">Dropdown Header:</div>
                          <a class="dropdown-item" href="#">Action</a>
                          <a class="dropdown-item" href="#">Another action</a>
                          <div class="dropdown-divider"></div>
                          <a class="dropdown-item" href="#">Something else here</a>
                        </div>
                      </div>
                    </div>
                    <div class="card-body">
                      <div class="chart-pie pt-4 pb-2">
                        <canvas id="myPieChart"></canvas>
                      </div>
                      <div class="mt-4 text-center small">
                        <span class="mr-2">
                          <i class="fas fa-circle text-primary"></i> Active 300
                    </span>
                        <span class="mr-2">
                          <i class="fas fa-circle text-success"></i> Inactive 100
                    </span>
                        <span class="mr-2">
                          <i class="fas fa-circle text-info"></i> Temporary 50
                    </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}

              </div>

              <div class="row">

                <div class="col-lg-6 mb-4">

                  <div class="card shadow mb-4">
                    <div class="card-header py-3">
                      <h6 class="m-0 font-weight-bold text-primary">Meal Timing</h6>

                    </div>
                    <div class="card-body">


                      <div class="table-responsive">


                        {this.state.modalIsOpen && <div style={{ margin: 50 }}
                        >



                          <Modal
                            appElement={document.getElementById('123')}
                            isOpen={this.state.modalIsOpen}
                            onAfterOpen={this.afterOpenModal}
                            onRequestClose={this.closeModal}
                            style={customStyles}
                            contentLabel="Example Modal"
                          >
                            <h2 >Select Date</h2>






                            <Calendar
                              maxDate={new Date()}
                              onChange={this.onChange}
                              value={this.state.calDate}
                            />

                          </Modal>


                        </div>}



                        <a href="#" class="d-none d-sm-inline-block btn btn-sm btn-primary shadow-sm float-right mb-3"><i class="fas fa-plus fa-sm text-white-50"></i> Add Meal</a>
                        <table class="table table-bordered" id="dataTable" width="100%" cellspacing="0">


                          <tbody>
                            <tr>
                              <td>Breakfast</td>
                              <td>8:00am - 10:00am</td>
                              <td><i class="fas fa-user-edit pl-1"></i><i class="far fa-trash-alt pl-1"></i></td>
                            </tr>
                            <tr>
                              <td>Lunch</td>
                              <td>12:30pm - 2:00pm</td>
                              <td><i class="fas fa-user-edit pl-1"></i><i class="far fa-trash-alt pl-1"></i></td>
                            </tr>
                            <tr>
                              <td>Dinner</td>
                              <td>8:30pm - 9:30pm</td>
                              <td><i class="fas fa-user-edit pl-1"></i><i class="far fa-trash-alt pl-1"></i></td>
                            </tr>
                            <tr>
                              <td>Supper</td>
                              <td>12:30pm - 2:00pm</td>
                              <td><i class="fas fa-user-edit pl-1"></i><i class="far fa-trash-alt pl-1"></i></td>
                            </tr>
                            <tr>
                              <td>Tea</td>
                              <td>12:30pm - 2:00pm</td>
                              <td><i class="fas fa-user-edit pl-1"></i><i class="far fa-trash-alt pl-1"></i></td>
                            </tr>
                            <tr>
                              <td>Snack</td>
                              <td>12:30pm - 2:00pm</td>
                              <td><i class="fas fa-user-edit pl-1"></i><i class="far fa-trash-alt pl-1"></i></td>
                            </tr>

                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>


                </div>
                {this.state.companyList !== undefined && this.state.companyList.length > 0 &&
                  <div class="col-lg-6 mb-4">

                    <div class="card shadow mb-4">
                      <div class="card-header py-3">
                        <h6 class="m-0 font-weight-bold text-primary">Company Details</h6>
                      </div>
                      <div class="card-body">
                        <div class="text-center">
                          <h2>{this.state.companyList[0].Name + ""}</h2>
                          {/* <img class="img-fluid px-3 px-sm-4 mt-2 mb-2" src="img/Dixon-Technology-logo.png" alt="" /> */}
                        </div>
                        <h5 class="text-center font-weight-bold">{}</h5>
                        <p class="text-center">GSTIN : {this.state.companyList[0].GST + ""}<br /> PAN : {this.state.companyList[0].PAN + ""} </p>
                        <h5 class="font-weight-bold">Address</h5>
                        <p>{this.state.companyList[0].Address + ""}</p>
                      </div>
                    </div>


                  </div>}
              </div>

            </div>

          </div>
        </div>
      </div>
    )
  }


}
