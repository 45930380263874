import React, { Component, useState } from 'react'
import firebase from './Firebase'
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import Modal from 'react-modal'
// import {
//     BrowserRouter as Router,
//     Route,
//     Link
//   } from 'react-router-dom';
import cookie from 'react-cookies'
import moment from 'moment'

import DatePicker from 'react-datepicker'
import Calendar from 'react-calendar';


let db = firebase.firestore()



const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)'
    }
};



export default class FoodTransactionMonthly extends Component {



    constructor(props) {
        super(props)

        this.openModal = this.openModal.bind(this);
        this.afterOpenModal = this.afterOpenModal.bind(this);
        this.closeModal = this.closeModal.bind(this);

        this.state = {
            qrcodeValue: '',
            foodTransaction: [{
                'emp_id': '',
                'type': '',
                'time': '',

            }],
            loginUser: undefined,
            date: '',
            modalIsOpen: false,
            calDate: new Date(),
            teaCount: 0,
            lunchCount: 0,
            breakFastCount: 0,
            dinnerCount: 0,
            startDate: new Date(),
            endDate: new Date(),
            monthDate: '',
            startMonth: new Date()
        }
    }

    componentDidMount() {


        const login = cookie.load('login', { path: '/' });



        if (login === undefined) {
            this.props.history.push('/');
            return;
        }




        this.setState({ loginUser: JSON.parse(login) }, () => {


            this.setState({ date: this.getConversitionDate(new Date()), foodTransaction: [] }, () => {




                db.collection("foodMonth").doc("" + this.getConversitionMonth(new Date())).collection(this.state.loginUser.name)
                    .get()
                    .then((querySnapshot) => {

                        let array = querySnapshot.docs.map((documentSnapshot) => {
                            return {
                                ...documentSnapshot.data(),
                                time: moment(documentSnapshot.data().time.toDate()).format('hh:mm A')
                            }
                        });

                        let checkList = []

                        let monthlyData = []

                        let obj = {}

                        array.forEach(item => {

                            if (!checkList.includes(item.emp_id)) {
                                checkList.push(item.emp_id)
                                obj = {
                                    'tea': array.filter(x => x.type === 'tea' && x.emp_id === item.emp_id).length,
                                    'lunch': array.filter(x => x.type === 'lunch' && x.emp_id === item.emp_id).length,
                                    'dinner': array.filter(x => x.type === 'dinner' && x.emp_id === item.emp_id).length,
                                    'breakFast': array.filter(x => x.type === 'breakFast' && x.emp_id === item.emp_id).length,
                                    'emp_id': item.emp_id
                                }
                                monthlyData.push(obj)
                            }
                        })

                        this.setState({ foodTransaction: monthlyData }, () => {
                            this.countData()
                        })





                    })
                    .catch((error) => {
                        alert('error')
                        console.log("Error getting documents: ", error);
                    });







            })


        })








    }





    countData = () => {


        const foodTransaction = this.state.foodTransaction

        let lunch = 0, breakFast = 0, dinner = 0, tea = 0;

        foodTransaction.forEach(item => {

            lunch = lunch + item.lunch
            breakFast = breakFast + item.breakFast
            dinner = dinner + item.dinner
            tea = tea + item.tea



        })

        this.setState({
            lunchCount: lunch, breakFastCount: breakFast,
            dinnerCount: dinner, teaCount: tea
        })




    }

    getConversitionDate = (d) => {
        var today = d;
        var dd = String(today.getDate()).padStart(2, '0');
        var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
        var yyyy = today.getFullYear();
        today = dd + '-' + mm + '-' + yyyy;
        return today
    }

    getConversitionMonth = (d) => {
        var today = d;
        var dd = String(today.getDate()).padStart(2, '0');
        var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
        var yyyy = today.getFullYear();
        today = mm + '-' + yyyy;
        this.setState({ monthDate: today })
        return today
    }

    mainDashboard = () => {
        this.props.history.push('/mainDashboard');
    }
    logout = () => {
        cookie.remove('login', { path: '/' })
        this.props.history.push('/')
    }

    employeeDetails = () => {
        this.props.history.push('/employeeDetails');
    }

    companyDetails = () => {
        this.props.history.push('/companyDetails');
    }

    foodTransaction = () => {
        this.props.history.push('/foodTransaction')
    }

    calButton = () => {
        this.setState({ modalIsOpen: !this.state.modalIsOpen })

    }
    foodTransactionMonthly = () => {
        this.props.history.push('/foodTransactionMonthly')

    }

    openModal() {
        this.setState({ modalIsOpen: true });
    }

    afterOpenModal() {
        // references are now sync'd and can be accessed.
        //this.subtitle.style.color = '#f00';
    }

    closeModal() {
        this.setState({ modalIsOpen: false });
    }

    onChange = date => {
        this.setState({ calDate: date, modalIsOpen: false, date: this.getConversitionDate(date), foodTransaction: [] }, () => {
            db.collection("foodMonth").doc("" + this.getConversitionMonth(date)).collection(this.state.loginUser.name)
                .get()
                .then((querySnapshot) => {

                    let array = querySnapshot.docs.map((documentSnapshot) => {
                        return {
                            ...documentSnapshot.data(),
                            time: moment(documentSnapshot.data().time.toDate()).format('hh:mm A')
                        }
                    });

                    let checkList = []

                    let monthlyData = []

                    let obj = {}

                    array.forEach(item => {

                        if (!checkList.includes(item.emp_id)) {
                            checkList.push(item.emp_id)
                            obj = {
                                'tea': array.filter(x => x.type === 'tea' && x.emp_id === item.emp_id).length,
                                'lunch': array.filter(x => x.type === 'lunch' && x.emp_id === item.emp_id).length,
                                'dinner': array.filter(x => x.type === 'dinner' && x.emp_id === item.emp_id).length,
                                'breakFast': array.filter(x => x.type === 'breakFast' && x.emp_id === item.emp_id).length,
                                'emp_id': item.emp_id
                            }
                            monthlyData.push(obj)
                        }
                    })

                    this.setState({ foodTransaction: monthlyData }, () => {
                        this.countData()
                    })





                })
                .catch((error) => {
                    alert('error')
                    console.log("Error getting documents: ", error);
                });




        })

    }

    handleSelect(range) {
        console.log(range);
        // An object with two keys,
        // 'startDate' and 'endDate' which are Momentjs objects.
    }
    monthPicker = () => {
        return (
            <DatePicker
                selected={this.state.startMonth}
                onChange={date => this.setState({ startMonth: date })}
                showMonthDropdown
            />
        );


    }


    render() {
        const options = {
            paginationSize: 50,
            paginationShowsTotal: true,
            searchDelayTime: 500,
            sizePerPage: 50,
        }




        return (
            <div id="wrapper" >


                <ul class="navbar-nav bg-gradient-primary sidebar sidebar-dark accordion" id="accordionSidebar">

                    <a class="sidebar-brand d-flex align-items-center justify-content-center" href="index.html">
                        <div class="sidebar-brand-icon rotate-n-15">
                            <i class="fas fa-laugh-wink"></i>
                        </div>
                        <div class="sidebar-brand-text mx-3">{this.state.loginUser !== undefined ? this.state.loginUser.name : ''} Admin </div>
                    </a>

                    <hr class="sidebar-divider my-0"></hr>

                    <li class="nav-item active">
                        <a class="nav-link" onClick={this.mainDashboard}>
                            <i class="fas fa-fw fa-tachometer-alt"></i>
                            <span>Dashboard</span></a>
                    </li>

                    <hr class="sidebar-divider" />

                    <div class="sidebar-heading">
                        Interface
</div>

                    <li class="nav-item active">
                        <a class="nav-link" onClick={this.companyDetails}>
                            <i class="fas fa-fw fa-chart-area"></i>
                            <span>Companys Details</span></a>
                    </li>

                    <li class="nav-item active">
                        <a class="nav-link" onClick={this.employeeDetails}>
                            <i class="fas fa-fw fa-table"></i>
                            <span>Employee Details</span></a>
                    </li>

                    <li class="nav-item active">
                        <a class="nav-link" onClick={this.foodTransaction}>
                            <i class="fas fa-fw fa-table"></i>
                            <span>Food Transaction</span></a>
                    </li>

                    <li class="nav-item active">
                        <a class="nav-link" onClick={this.foodTransactionMonthly}>
                            <i class="fas fa-fw fa-table"></i>
                            <span>Food Transaction Monthly</span></a>
                    </li>

                    <li class="nav-item active">
                        <a class="nav-link" onClick={this.logout}>
                            <i class="fas fa-sign-out-alt"></i>
                            <span>logout</span></a>
                    </li>


                    <hr class="sidebar-divider d-none d-md-block"></hr>

                    <div class="text-center d-none d-md-inline">
                        <button ref={a => this.button = a} class="rounded-circle border-0" id="sidebarToggle"></button>
                    </div>


                </ul>
                <div class="container-fluid">
                    <div class="card shadow mb-4">
                        <div class="card-header py-3">
                            <h6 class="m-0 font-weight-bold text-primary float-left">Food Transaction {this.state.monthDate}</h6>


                            <div onClick={this.calButton} class="d-none d-sm-inline-block btn btn-sm btn-primary shadow-sm float-right"><i class="fas fa-plus fa-sm text-white-50"></i> Month Calendar</div>


                        </div>





                        <div class="row" style={{ marginTop: 20, marginLeft: 20 }}>

                            <div class="col-xl-3 col-md-6 mb-4">
                                <div class="card border-left-primary shadow h-100 py-2">
                                    <div class="card-body">
                                        <div class="row no-gutters align-items-center">
                                            <div class="col mr-2">
                                                <div class="text-xs font-weight-bold text-primary text-uppercase mb-1">Breakfast</div>
                                                <div class="h5 mb-0 font-weight-bold text-gray-800">{this.state.breakFastCount}</div>
                                            </div>
                                            <div class="col-auto">
                                                <i class="fas fa-calendar fa-2x text-gray-300"></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div class="col-xl-3 col-md-6 mb-4">
                                <div class="card border-left-success shadow h-100 py-2">
                                    <div class="card-body">
                                        <div class="row no-gutters align-items-center">
                                            <div class="col mr-2">
                                                <div class="text-xs font-weight-bold text-success text-uppercase mb-1">Lunch</div>
                                                <div class="h5 mb-0 font-weight-bold text-gray-800">{this.state.lunchCount}</div>
                                            </div>
                                            <div class="col-auto">
                                                <i class="fas fa-dollar-sign fa-2x text-gray-300"></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-xl-3 col-md-6 mb-4">
                                <div class="card border-left-info shadow h-100 py-2">
                                    <div class="card-body">
                                        <div class="row no-gutters align-items-center">
                                            <div class="col mr-2">
                                                <div class="text-xs font-weight-bold text-info text-uppercase mb-1">Dinner</div>
                                                <div class="row no-gutters align-items-center">
                                                    <div class="col-auto">
                                                        <div class="h5 mb-0 mr-3 font-weight-bold text-gray-800">{this.state.dinnerCount}</div>
                                                    </div>

                                                </div>
                                            </div>
                                            <div class="col-auto">
                                                <i class="fas fa-clipboard-list fa-2x text-gray-300"></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-xl-3 col-md-6 mb-4">
                                <div class="card border-left-warning shadow h-100 py-2">
                                    <div class="card-body">
                                        <div class="row no-gutters align-items-center">
                                            <div class="col mr-2">
                                                <div class="text-xs font-weight-bold text-warning text-uppercase mb-1">Tea</div>
                                                <div class="h5 mb-0 font-weight-bold text-gray-800">{this.state.teaCount}</div>
                                            </div>
                                            <div class="col-auto">
                                                <i class="fas fa-comments fa-2x text-gray-300"></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>






                        </div>













                        <div class="card-body">

                            {this.state.modalIsOpen && <div style={{ margin: 50 }}
                            >



                                <Modal
                                    appElement={document.getElementById('123')}
                                    isOpen={this.state.modalIsOpen}
                                    onAfterOpen={this.afterOpenModal}
                                    onRequestClose={this.closeModal}
                                    style={customStyles}
                                    contentLabel="Example Modal"
                                >
                                    <h2 >Select Date</h2>






                                    <Calendar
                                        maxDate={new Date()}
                                        onChange={this.onChange}
                                        value={this.state.calDate}
                                    />

                                </Modal>


                            </div>}
                            <div class="table-responsive">

                                <BootstrapTable data={this.state.foodTransaction} options={options} exportCSV={true} search={true} striped={true} bordered={true} hover={true} pagination={true}>
                                    <TableHeaderColumn width={'15%'} dataField="emp_id" isKey={true}  >Employee ID</TableHeaderColumn>
                                    <TableHeaderColumn width={'15%'} dataField="lunch" >Lunch</TableHeaderColumn>

                                    <TableHeaderColumn width={'15%'} dataField="dinner" >Dinner</TableHeaderColumn>

                                    <TableHeaderColumn width={'15%'} dataField="tea" >Tea</TableHeaderColumn>

                                </BootstrapTable>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
